export enum IframeEvents {
    SIGNUP_LOGIN = 'signup_login',
    SET_PARENT_URL = 'set_parent_url',
}

export const WIDGET_CONSTANTS = {
    WIDGET_QUESTION_POPUP: 'widget_question_popup',
    STYLE_ASSISTANT_SOURCE: 'style_assistant_source',
    STYLE_ASSISTANT_SOURCE_CONVERSATION: 'style_assistant_source_conversation_id',
    TAFFI_WIDGET_OPENED: 'taffi_widget_opened',
};
