import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';

// console.log('env=', process.env)
// ToDo: is build time better ?
const isProductionServer = window.location.hostname.toLowerCase().includes(process.env.REACT_APP_PRODUCTION_DOMAIN);
console.log('isProductionServer2=', isProductionServer);

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_CDN,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    databaseURL: process.env.REACT_APP_REALTIME_DATABASE_URL,
});
const realtimeDatabase = app.database();
const firestore = app.firestore();
const auth = app.auth();
const functions = app.functions();
const storage = app.storage();
const messaging = null; //process.env.REACT_APP_USE_EMULATORS ? null : app.messaging();
let analytics = null;

firebase.analytics.isSupported().then(function (result) {
    console.debug('analytics=', result);
    analytics = result ? firebase.analytics() : null;
});
// REF: https://developers.google.com/gtagjs/reference/
const logEvent = function (eventName, eventParams) {
    if (!analytics) {
        return;
    }
    analytics.logEvent(eventName, eventParams);
};

// if (process.env.NODE_ENV === "development") {
//   // app.functions().useEmulator("localhost:5001");
// }

if (!isProductionServer && process.env.REACT_APP_USE_EMULATORS) {
    const host = process.env.REACT_APP_EMULATOR_HOST || 'localhost';
    console.log('***EMULATOR MODE***', host);
    // firestore.useEmulator("localhost", 8080);
    // auth.useEmulator("http://localhost:9099/", { disableWarnings: true });
    // functions.useEmulator("localhost", 5001);
    firestore.useEmulator(`${window.location.protocol}//${window.location.hostname}`, 8080);
    auth.useEmulator(`${window.location.protocol}//${window.location.hostname}:9099/`, { disableWarnings: true });
    functions.useEmulator(`${window.location.protocol}//${window.location.hostname}`, 5001);
    // realtimeDatabase.useEmulator('localhost', 9001);
}

const database = {
    realtimeDatabase: realtimeDatabase,
    firestore: firestore,
    users: firestore.collection('users'),
    products: firestore.collection('products'),
    styleRequests: firestore.collection('styleRequests'),
    timeStamp: firestore.Timestamp,

    formatDoc: (doc) => {
        return { id: doc.id, ...doc.data() };
    },
    getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

//FCM - Firebase notification

export const getToken = (setTokenFound) => {
    if (!messaging) return null;

    return messaging
        .getToken({
            vapidKey: 'BCfzWETsXeLHu3sJs7fkuEtGxihXe-f7y1y6OQsUITzPSIVcKaSMDo3DRtMf1Ffd4AlrhFz42k1kHCmBQFBHl4Y',
        })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for subscription FCM: ===============================> ', currentToken);
                setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server to check subscription
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ============= ', err);
            // catch error while creating client token
        });
};

// FCM TO FOREGROUND

export const onMessageListener = () =>
    new Promise((resolve, reject) => {
        if (!messaging) {
            reject();
            return;
        }
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
// export const auth = app.auth();
// export const functions = app.functions();
export { auth, functions, database, logEvent, isProductionServer, storage };
export default app;
